import React, { useState } from "react";
import { useSelector } from "react-redux";

import ButtonBig from "@/base/components/button-big/index.js";
import LoaderSmall from "@/base/components/loader-small/index.js";
import PopupWindow from "@/base/components/popup-window/index.js";

import app from "@/app/app.js";
import api from "@/app/api.js";

import settings from "@/app/settings.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
});

const TeacherPopupStudentsRemove = (props) => {
    const [statuses, setStatuses] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const store = useSelector(storeSelector);

    /* --- */

    const onClose = () => {
        if (isFinished) {
            props.onCloseWithFinishedStatus();
            return;
        }

        props.onClose();
    };

    const onStartRemoveProcess = async () => {
        if (isDeleting) {
            return;
        }

        setIsDeleting(true);

        const newStatuses = [];

        for (let i = 0; i < props.students.length; i += 1) {
            const { id } = props.students[i];

            const res = await api.classes.removeStudentById({ // eslint-disable-line no-await-in-loop, max-len
                session: store.session,
                classId: props.classId,
                studentId: id,
            });

            const status = {
                id,
                ok: res.ok,
            };

            newStatuses.push(status);
        }

        setStatuses(newStatuses);
        setIsFinished(true);

        if (settings.features.CLASSES_LIMIT) {
            app.actions.common.user.reloadUserClassesLimitInfo();
        }
    };

    /* --- */

    const renderContent = () => {
        const students = props.students.map((s) => {
            let status = null;

            if (isDeleting) {
                for (let i = 0; i < statuses.length; i += 1) {
                    const st = statuses[i];

                    if (st.id === s.id) {
                        status = st.ok
                            ? "Removed!"
                            : "Failed!";

                        break;
                    }
                }

                if (!status) {
                    status = (
                        <LoaderSmall />
                    );
                }
            }

            return (
                <div className={styles.studentRow}>
                    <div>
                        {s.fullName}
                    </div>
                    <div>
                        {status}
                    </div>
                </div>
            );
        });

        let controls = null;

        if (isFinished) {
            controls = (
                <ButtonBig
                    onClick={onClose}
                >
                    Close
                </ButtonBig>
            );
        } else {
            controls = (
                <ButtonBig
                    isRoseTheme
                    disabled={isDeleting}
                    onClick={onStartRemoveProcess}
                >
                    Delete
                </ButtonBig>
            );
        }

        return (
            <div className={styles.content}>
                <div className={styles.studentsList}>
                    {students}
                </div>
                {controls}
            </div>
        );
    };

    return (
        <PopupWindow
            isSmall
            isCentered
            hideClose={isDeleting && !isFinished}
            title="Delete students"
            onClose={onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

TeacherPopupStudentsRemove.defaultProps = {
    classId: null,
    students: [],
    onClose: () => { },
    onCloseWithFinishedStatus: () => { },
};

export default TeacherPopupStudentsRemove;
